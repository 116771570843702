import noop from 'lodash/noop';
import React from 'react';

export type UpdateProgressBarState = (values: {
  progressValue?: number;
  isVisible?: boolean;
}) => void;

interface ProgressBarState {
  isVisible: boolean;
  progressValue: number;
  updateState: UpdateProgressBarState;
}

const initialState: ProgressBarState = {
  isVisible: false,
  progressValue: 0,
  updateState: noop,
};

export const ProgressBarContext = React.createContext<ProgressBarState>(
  initialState
);
