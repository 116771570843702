import { NetworkStatus } from '@apollo/client';
import { useGetInstitutionsListQuery } from '@generated';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useAuthenticatedUser } from 'src/services/authentication/hooks';
import { extractNonNullItems } from 'src/utils/extractNonNullItems';

import { NetWorthContext, NetWorthContextValue } from './NetWorthContext';

export const NetWorthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuthenticatedUser();
  const customerUuid: string | undefined = user?.customer?.uuid;

  const [isConnectingInstitution, setIsConnectingInstitution] = useState(false);

  const {
    data: institutionsList,
    networkStatus,
    refetch,
  } = useGetInstitutionsListQuery({
    fetchPolicy: 'network-only',
    variables: customerUuid ? { customer: customerUuid } : undefined,
    skip: !customerUuid,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (networkStatus === NetworkStatus.ready) {
      setIsConnectingInstitution(false);
    }
  }, [networkStatus]);

  const rawCustomerInstitutions = institutionsList?.customerInstitutions;

  const contextValue: NetWorthContextValue = useMemo(() => {
    const customerInstitutions = extractNonNullItems(rawCustomerInstitutions);

    return {
      startConnectingInstitution: () => setIsConnectingInstitution(true),
      updateConnectedInstitutions: refetch,
      isConnectingInstitution,
      isMissingConnectedInstitution: customerInstitutions.length < 1,
    };
  }, [
    setIsConnectingInstitution,
    refetch,
    rawCustomerInstitutions,
    isConnectingInstitution,
  ]);

  return (
    <NetWorthContext.Provider value={contextValue}>
      {children}
    </NetWorthContext.Provider>
  );
};
